<template>
  <div class="d-flex flex-column justify-content-around align-items-center h-100 position-relative">
    <div class="mb-auto" style="height: 18%">
    </div>
    <div class="info mx-auto text-center">
      <div class="">
        <h1 class="position-relative z-3 font-weight-bold">
          Join others who are already enjoying this great privilege
        </h1>
      </div>
      <div class="mt-3 mb-2 position-relative z-3">
        <p>Click here to Apply</p>
      </div>
      <div class="">
        <b-button class="secondary-bg px-5 py-1 position-relative z-3" @click="$router.push({ name: 'gradloans-contact'})">
          Apply now
        </b-button>
      </div>
      <div class="mt-2">
        <img class="position-relative z-3 mem1" src="@/assets/images/mems/m1.png" height="250" alt="mem-icon" />
      </div>

    </div>
    <div class="mb-auto">
      .
    </div>
    <div class="m2">
      <div class="w-100 d-flex justify-content-md-around justify-content-between">
        <div class="">
          <img src="@/assets/images/mems/m3.png" class="mem" height="130" alt="exxtra-logo" /></div>
        <div class="">
          <img src="@/assets/images/mems/m2.png" class="mem" height="150" alt="exxtra-logo" />
        </div>
      </div>
    </div>
    <div class="m3">
      <div class="w-100 d-flex justify-content-around">
        <div class="">
          <img src="@/assets/images/mems/m4.png" class="mem" height="120" alt="exxtra-logo" />
        </div>
        <div class="">
          <img src="@/assets/images/mems/m5.png" class="mem" height="120" alt="exxtra-logo" />
        </div>
      </div>
    </div>
    <div class="m4">
      <div class="w-100 d-flex justify-content-between">
        <div class="">
          <img src="@/assets/images/mems/m8.png" class="mem" height="160" alt="exxtra-logo" />
        </div>
        <div class="">
          <img src="@/assets/images/mems/m7.png" class="mem" height="160" alt="exxtra-logo" />
        </div>
      </div>
    </div>
    <div class="m5">
      <div class="w-100 d-flex justify-content-between">
        <div class="">
          <img src="@/assets/images/mems/m9.png" class="mem" height="160" alt="exxtra-logo" />
        </div>
        <div class="">
          <img src="@/assets/images/mems/m6.png" class="mem" height="160" alt="exxtra-logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import M1 from '@/assets/svg/memoji1.svg';
import { gsap, ScrollTrigger } from "gsap/all";

export default {
  name: "JoinGraduates",
  components: {
    BButton,
    M1,
    ScrollTrigger
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.utils.toArray(".mem").forEach(
        (mem,) => {
          const serviceTl = gsap.timeline({
            scrollTrigger: {
              trigger: mem,
              toggleActions: 'restart none none none',
            }
          });

          serviceTl.fromTo(mem,
              {
                opacity: 0,
                y: "50%"
              },
              {
                opacity: 1,
                duration: 1.5,
                y: 0,
                ease: "back.inOut",
                stagger: 0.8,
              });
        }
    )
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/base/pages/page-gradloans.scss';

.z-3 {
  z-index: 3;
}

.m2 {
  position: absolute;
  z-index: 2;
  bottom: 30%;
  left: 20%;
  right: 20%;
  //width: 50%;

  @media screen and (max-width: 768px) {
    left: 1%;
    right: 1%;
    bottom: 43%;

    img {
      height: 70px;
    }
  }

  img {
    z-index: -1;
  }
}

.m3 {
  position: absolute;
  z-index: 2;
  bottom: 12%;
  left: 25%;
  right: 25%;
  //width: 50%;

  @media screen and (max-width: 768px) {
    display: none;
  }

  img {
    z-index: -1;
  }
}

.m4 {
  background-color: white;
  position: absolute;
  z-index: 1;
  bottom: 20%;
  left: 12%;
  right: 11%;

  img {
    z-index: 1;
  }
}

.m5 {
  background-color: white;
  position: absolute;
  z-index: 1;
  bottom: 50%;
  left: 10%;
  right: 10%;

  img {
    z-index: 1;
  }

  @media screen and (max-width: 768px) {
    bottom: 80%;

    img {
      height: 120px;
    }
  }
}


.info {
  width: 60%;

  h1 {
    font-size: 4rem;
    line-height: 1.2;
  }

  @media screen and (max-width: 768px){
    width: 90%;

    h1 {
      font-size: 2.5rem;
      line-height: 1.2;
    }
  }
}


</style>
