<template>
  <div class="h-100 position-relative">
    <div class="flex-column d-flex h-100 justify-content-center align-items-center w-100">
      <div class="w-100 row row-cols-md-2 row-cols-1 align-items-center">
        <div class="p-2 p-md-4">
          <div>
            <h1 class="heading-main font-weight-bolder secondary-heading mb-md-4">
              About
            </h1>
          </div>
          <div>
            <h1 class="text-white">
              Exxtra Grad Loan is open to all graduate students across the globe who want to pursue higher education in the U.S. or Canada.
              We provide access to credit (up to USD 100,000) for students who have secured admissions to accredited higher education institutions or universities covering tuition fees, accommodation expenses etc.
            </h1>
          </div>
          <div class="mt-2 pt-2">
            <b-button class="secondary-bg px-md-5 py-md-1" @click="$router.push({ name: 'gradloans-contact'})">
              Apply Now
            </b-button>
          </div>
        </div>
        <div>
          <div class="p-2 p-md-4">
            <h1 class="text-white pr-2">
              We believe higher education provides students with the requisite skills needed to meet the demands of the ever-changing labour market. We do not underestimate the value higher education plays in producing exceptional leaders and human capital to help build sustainable economies and social development across the globe.
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  name: "GraduateAbout",
  components: {
    BButton
  }
}
</script>

<style scoped lang="scss">

.heading-main {
  font-size: 3.5rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

h1 {
  line-height: 1.5;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
}


$exxtra-primary: #144F6A;
$exxtra-secondary: #EAF2CE;

.secondary-heading {
  color: $exxtra-secondary;
}

.secondary-bg {
  background-color: $exxtra-secondary !important;
  color: $exxtra-primary !important;
}

</style>
