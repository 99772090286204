<template>
  <div class="h-100 position-relative">
    <div class="flex-column d-flex h-100 p-4 p-md-1 justify-content-center align-items-center w-100">
      <div class="w-100 row row-cols-md-2 row-cols-1 align-items-center">
        <!--1st page-->
        <div class="col">
        </div>
        <!--2nd page -->
        <div class="col">
          <div>
            <h1 class="heading-main font-weight-bolder secondary-heading mb-4">
              Why choose us
            </h1>
          </div>
          <div>
            <h1 class="text-white">
              <ul>
                <li class="my-1">
                  <h1 class="text-white">No collateral needed. No guarantor needed. Once you are eligible, you can start the process.
                  </h1>
                </li>
                <li class="my-1">
                  <h1 class="text-white">
                    Fast and secured process.
                  </h1>
                </li>
                <li class="my-1">
                  <h1 class="text-white">
                    Re-payment period of 10years with a possible extension of 2 years.
                  </h1>
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  name: "GraduateChoose",
  components: {
    BButton
  }
}
</script>

<style scoped lang="scss">

h1 {
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
}

.heading-main {
  font-size: 3.5rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}


$exxtra-primary: #144F6A;
$exxtra-secondary: #EAF2CE;

.secondary-heading {
  color: $exxtra-secondary;
}

.secondary-bg {
  background-color: $exxtra-secondary !important;
  color: $exxtra-primary !important;
}

</style>
