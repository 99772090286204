<template>
  <div class="h-100 position-relative">
    <div class="flex-column d-flex h-100 justify-content-center align-items-center w-100">
      <div class="w-100 row row-cols-md-2 row-cols-1 align-items-center">
        <!--1st page-->
        <div class="col">
        </div>
        <!--2nd page -->
        <div class="col">
          <div>
            <h1 class="heading-main font-weight-bolder secondary-heading mb-4">
              Re-payment Plan
            </h1>
          </div>
          <div>
            <h1 class="text-white">
              We make it possible to pay off your loan without penalty or default. Students enjoy flexible payment terms. Once you commence your masters’ program, you are given a period of 10 years with a possible extension of 2 years to pay off your loan.
            </h1>
          </div>
          <div class="mt-2 pt-2">
            <b-button class="secondary-bg px-md-5 py-md-1" @click="$router.push({ name: 'gradloans-contact'})">
              Apply Now
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  name: "GraduateRepayment",
  components: {
    BButton
  }
}
</script>

<style scoped lang="scss">

.heading-main {
  font-size: 3.5rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

h1 {
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
}


$exxtra-primary: #144F6A;
$exxtra-secondary: #EAF2CE;

.secondary-heading {
  color: $exxtra-secondary;
}

.secondary-bg {
  background-color: $exxtra-secondary !important;
  color: $exxtra-primary !important;
}

</style>
