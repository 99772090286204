<template>
  <section class="graduate-section">
    <div class="wrapper">
      <!--<section class="panel bg-danger">-->
      <!--  one-->
      <!--</section>-->
      <!--<section class="panel bg-success">two-->
      <!--</section>-->
      <!--<section class="panel bg-warning">-->
      <!--  three-->
      <!--</section>-->
      <section class="panel">
        <graduate-home />
      </section>
      <section class="panel bg-danger">
        <graduate-about />
      </section>
      <section class="panel">
        <graduate-eligibility />
      </section>
      <section class="panel">
        <graduate-repayment />
      </section>
      <section class="panel">
        <graduate-choose />
      </section>
      <section class="panel bg-primary">
        <join-graduates />
      </section>
    </div>

    <!--<div>-->
    <!--  <div id="screen-1" class="screen">-->
    <!--    screen1-->
    <!--  </div>-->

    <!--  <div id="screen-2" class="screen">-->
    <!--    <div class="slides" id="slides-container">-->
    <!--      <div class="slide">-->
    <!--        <img src="https://via.placeholder.com/728x400.jpg?text=1"/>-->
    <!--      </div>-->
    <!--      <div class="slide"><img src="https://via.placeholder.com/300x600.jpg?text=2"/></div>-->
    <!--      <div class="slide"><img src="https://via.placeholder.com/728x400.jpg?text=3"/></div>-->
    <!--      <div class="slide"><img src="https://via.placeholder.com/300x600.jpg?text=4"/></div>-->
    <!--      <div class="slide"><img src="https://via.placeholder.com/728x400.jpg?text=5"/></div>-->
    <!--      <div class="slide"><img src="https://via.placeholder.com/300x600.jpg?text=6"/></div>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</div>-->
  </section>
</template>

<script>
import Arrow from '@/assets/svg/Arrow.svg';
import GraduateAbout from "@/pages/public/gradloans/sections/about.vue";
import GraduateChoose from "@/pages/public/gradloans/sections/choose.vue";
import GraduateEligibility from "@/pages/public/gradloans/sections/eligibility.vue";
import GraduateHome from "@/pages/public/gradloans/sections/home.vue";
import JoinGraduates from "@/pages/public/gradloans/sections/join.vue";
import GraduateRepayment from "@/pages/public/gradloans/sections/repayment.vue";
import { BImg, BButton } from 'bootstrap-vue'
import { gsap, ScrollTrigger } from 'gsap/all'

export default {
  name: "GradLoans",
  components: {
    GraduateChoose,
    GraduateRepayment,
    GraduateEligibility,
    GraduateAbout,
    GraduateHome,
    JoinGraduates,
    BImg,
    Arrow,
    BButton
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const sections = gsap.utils.toArray(".panel");

    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".wrapper",
        pin: true,
        scrub: 1,
        snap: 1 / (sections.length - 1),
        end: () => "+=600%",
      }
    });
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/base/pages/page-gradloans.scss';

.graduate-section {
  height: 100vh;
  overscroll-behavior: none;
}
.wrapper {
  overscroll-behavior: none;
  width: 600%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.panel{
  height: 100vh;
  width: auto;
  min-width: 100vw;
}


</style>
