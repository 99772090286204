<template>
    <div class="h-100 ">
      <div class="d-flex mx-auto content h-100 flex-column align-items-center justify-content-between">
        <div class="pt-2">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <!--style="transform: rotate(-0.25turn);-->
              <div>
                <img src="@/assets/images/exxtra/exxtra-logo.png" width="70px" alt="exxtra-logo" />
              </div>
            </div>
          </div>
        </div>
        <div class="row row-cols-md-2 row-cols-1 align-items-center">
          <div class="col">
            <div>
              <h1 class="heading-main first-head font-weight-bolder">
                We are experts in digital lending. <br />
                Study abroad with ease. <br />
              </h1>
              <h1 class="subtitle">
                Secure an International Students Loan now !!!
              </h1>
            </div>
            <div>
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <Arrow />
                </div>
                <div>
                  <p class="m-0 p-0 lead text-white scroll">
                    Scroll down to find out more
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col d-none d-md-block">
            <lottie-animation ref="anim" :animation-data="animationData" :loop="true" :auto-play="true" :speed="1" />
          </div>
        </div>
        <div>.</div>
        <div>.</div>
      </div>
      <div class="plane1 position-absolute w-100">
        <div class="w-100 d-flex align-items-center justify-content-center">
          <Plane2 />
          <Plane1 />
          <Plane3 />
        </div>
      </div>

    </div>
</template>

<script>
import Arrow from '@/assets/svg/Arrow.svg';
import LottieAnimation from 'lottie-web-vue'
import Plane2 from "@/assets/svg/Plane2.svg";
import Plane3 from "@/assets/svg/Plane3.svg";
import Plane1 from "@/assets/svg/Plane1.svg";
import { gsap, ScrollTrigger } from 'gsap/all'
import GraduateLoanBanner from "@/assets/lottie-files/GraduateLoanBanner.json";

export default {
  name: "GraduateHome",
  components: {
    Plane1,
    Plane2,
    Arrow,
    Plane3,
    LottieAnimation
  },
  data() {
    return {
      animationData: GraduateLoanBanner
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger)
    gsap.fromTo(".first-head",
        {
          opacity: 0,
          y: "30%"
        },
        {
          opacity: 1,
          duration: 1.5,
          y: 0,
          ease: "back.inOut",
          stagger: 0.1
        });

    gsap.fromTo(".subtitle",
        {
          opacity: 0,
          y: "30%"
        },
        {
          opacity: 1,
          duration: 1.7,
          y: 0,
          ease: "power4.inOut",
          stagger: 0.1
        })

    gsap.fromTo(".scroll",
        {
          scrollTrigger: {
            trigger: ".scroll"
          },
          opacity: 0,
          x: "-30%"
        },
        {
          opacity: 1,
          duration: 1.7,
          x: 0,
          ease: "power4.inOut",
          stagger: 0.1
        })
  }
}
</script>

<style scoped lang="scss">
.plane1 {
  bottom: 5%;
}

.content {
  width: 85%;
}

.heading-main {
  font-size: 3.5rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}
h1 {
  color: #EAF2CE;
}

</style>
