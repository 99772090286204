<template>
  <div class="h-100 w-100 position-relative">
    <div class="flex-column d-flex h-100 p-4 p-md-1 justify-content-center align-items-center w-100">
      <div class="w-100 row row-cols-md-2 row-cols-1  align-items-center">
        <div class="">
        </div>
        <div>
          <div class="">
            <div><h1 class="heading-main font-weight-bolder secondary-heading mb-2">
              Who is eligible ?
            </h1>
            </div>
            <div>
              <h1 class="text-white">
                You can apply for an international students loan if ...
              </h1>
            </div>
            <ul class="text-white font-weight-bold">
              <li class="my-2">
                <h1 class="text-white">
                  You are a graduate
                </h1>
              </li>
              <li class="my-2">
                <h1 class="text-white">
                  You have gained an admission and have been issued with a letter to pursue your Masters in any of the
                  STEM courses (Science, Technology, Engineering and Mathematics) and MBA.</h1>
              </li>
              <li class="my-2">
                <h1 class="text-white">
                  The university of your admission is in the U.S. or Canada.
                </h1>
              </li>
              <li class="my-2">
                <h1 class="text-white">
                  You have evidence of a valid national ID.
                </h1>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "GraduateEligibility",
  components: {}
}
</script>

<style scoped lang="scss">

h1 {
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
}

.heading-main {
  font-size: 3.5rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}


$exxtra-primary: #144F6A;
$exxtra-secondary: #EAF2CE;

.secondary-heading {
  color: $exxtra-secondary;
}

.secondary-bg {
  background-color: $exxtra-secondary !important;
  color: $exxtra-primary !important;
}

</style>
